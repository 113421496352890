import { React, useState } from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import Testimonials from "../components/Repeating/Testimonials";
import ValueProps from "../components/Repeating/ValueProps";
import CallToAction from "../components/Repeating/CTA";
import ButtonWithIcon from "../components/Button/ButtonWithIcon";

const Page = ({ data }) => {
  const [accordionOpen1, setAccordionOpen1] = useState("block"),
    [accordionOpen2, setAccordionOpen2] = useState("hidden"),
    [accordionOpen3, setAccordionOpen3] = useState("hidden"),
    [accordionOpen4, setAccordionOpen4] = useState("hidden"),
    [accordionOpen5, setAccordionOpen5] = useState("hidden"),
    [accordionOpen6, setAccordionOpen6] = useState("hidden");

  const specialties = [
    {
      id: 1,
      heading: "Luxury Hotels",
      text: "We provide top-quality, five-star services, so your luxury hotel has an optimal guest experience.",
      imageDesktop: data.luxuryHotelsDesktop.childImageSharp.gatsbyImageData,
      imageMobile: data.luxuryHotelsDesktop.childImageSharp.gatsbyImageData,
    },
    {
      id: 2,
      heading: "Resorts",
      text: "Our skilled team ensures your resort guests will have a relaxing, enjoyable stay.",
      imageDesktop: data.resortsDesktop.childImageSharp.gatsbyImageData,
      imageMobile: data.resortsDesktop.childImageSharp.gatsbyImageData,
    },
    {
      id: 3,
      heading: "Spas",
      text: "We use state-of-the-art cleaning processes, so your spa is a clean and sanitary facility.",
      imageDesktop: data.spasDesktop.childImageSharp.gatsbyImageData,
      imageMobile: data.spasDesktop.childImageSharp.gatsbyImageData,
    },
    {
      id: 4,
      heading: "Restaurants",
      text: "Our professional cleaning services keep your restaurant’s kitchen and dining spaces sanitary.",
      imageDesktop: data.restaurantsDesktop.childImageSharp.gatsbyImageData,
      imageMobile: data.restaurantsDesktop.childImageSharp.gatsbyImageData,
    },
    {
      id: 5,
      heading: "Event Spaces",
      text: "Our team of cleaning experts can clean before, during, and after your event.",
      imageDesktop: data.eventSpacesDesktop.childImageSharp.gatsbyImageData,
      imageMobile: data.eventSpacesDesktop.childImageSharp.gatsbyImageData,
    },
    {
      id: 6,
      heading: "Commercial Facilities",
      text: "We keep your commercial property sparkling - from restrooms to floors to pressure washing.",
      imageDesktop: data.commercialDesktop.childImageSharp.gatsbyImageData,
      imageMobile: data.commercialDesktop.childImageSharp.gatsbyImageData,
    },
  ];

  return (
    <Layout headerStyle="overlap">
      <SearchEngineOptimization
        title="About Hotel Cleaning Services | Hospitality Staffing"
        description="Learn about Hotel Cleaning Services, one of the largest and most trusted cleaning and hospitality staffing companies in the United States."
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />
      <section className="relative mb-28 md:mb-32 overflow-x-hidden md:overflow-visible">
        <div className="relative md:static mr-14 md:mr-0">
          <div className="md:absolute md:right-0">
            <GatsbyImage
              image={data.hero.childImageSharp.gatsbyImageData}
              className="md:h-full md:w-[60vw] ml-4 -mr-16"
            />
          </div>
          <div className="border border-primary-600 max-w-[585px] max-h-[576px] w-full h-full absolute top-14 left-0 md:hidden"></div>
        </div>
        <div className="container relative">
          <div className="grid md:grid-cols-12 md:gap-x-10 lg:gap-x-18 items-center md:h-[704px]">
            <header className="md:col-start-1 md:col-span-11 md:pt-36 z-10">
              <h1 className="relative md:left-10 lg:left-18 max-w-xl -top-12 md:top-0 -mt-2 md:mt-0 mb-0 md:mb-6">
                About Hotel Cleaning Services
              </h1>
              <div className="md:max-w-sm">
                <p className="text-sm md:text-base">
                  Hotel Cleaning Services’ success story began in 1983 in Kansas
                  City, Missouri. Our founder, John Knoepker, launched the
                  company with a vision for reshaping the cleaning service
                  industry.
                </p>
                <p className="mb-0 text-sm md:text-base">
                  Today, we are one of the largest and most trusted cleaning and
                  hospitality staffing companies in the United States. We
                  provide an outstanding level of service and cleanliness for
                  our resort, hotel, spa, restaurant, and commercial clients,
                  consistently exceeding expectations.
                </p>
              </div>
            </header>

            <div className="border border-primary-600 w-[708px] h-[588px] absolute top-36 right-24 md:col-end-13 md:col-span-1 hidden md:block"></div>
          </div>
        </div>
      </section>

      <ValueProps />

      <section className="mb-22 md:mb-32">
        <div className="container">
          <div className="grid md:grid-cols-2 gap-y-10 md:gap-x-10 lg:gap-x-20 items-center">
            <div className="order-2 md:order-1">
              <h2>Our Green Practices</h2>
              <p className="text-sm md:text-base mb-8 md:mb-10">
                Being ecologically responsible is more than a badge for us, it’s
                a high standard. From the certified environmentally approved
                products and supplies that we use every day to the eco-friendly
                equipment we bring with us, 'going green' is one of our core
                practices.
              </p>

              <div className="flex items-center justify-between md:justify-start space-x-4 md:space-x-10 lg:space-x-20 opacity-30 mb-8">
                <GatsbyImage
                  image={data.cimsHonors.childImageSharp.gatsbyImageData}
                />
                <GatsbyImage
                  image={data.cimsGBHonors.childImageSharp.gatsbyImageData}
                />
                <GatsbyImage
                  image={data.USGBC.childImageSharp.gatsbyImageData}
                />
              </div>

              <ButtonWithIcon
                href="/sustainability/"
                text={["Learn ", <br />, "More"]}
              />
            </div>

            <div className="flex justify-end order-1 md:order-2 -mx-4 md:mx-0">
              <GatsbyImage
                image={data.sustainability.childImageSharp.gatsbyImageData}
                width="560"
                className="ml-auto"
              />
            </div>
          </div>
        </div>
      </section>

      <Testimonials />

      <section className="mb-28 md:mb-32">
        <div className="container">
          <header className="mb-8 md:mb-12 text-center md:text-left">
            <h2>Our Specialties</h2>
          </header>
          <div className="grid md:grid-cols-2 gap-y-5 md:gap-x-12 lg:gap-x-20 items-center -mx-4 md:mx-0">
            <div className="order-2 md:order-1">
              <Accordion preExpanded={[1]}>
                {specialties.map((specialty, i) => (
                  <AccordionItem
                    className="accordion-item relative last-of-type:mb-0 mb-4 md:mb-8"
                    uuid={specialty.id}
                    key={specialty.id}
                  >
                    <AccordionItemHeading className="accordion-heading">
                      <AccordionItemButton className="accordion-button opacity-30 flex flex-col items-start text-left ml-10 md:ml-24 focus:outline-none cursor-pointer before:block before:absolute before:left-0 before:bg-primary-500 before:h-px before:w-0 before:mt-3 before:transition-all before:duration-300 before:ease-linear">
                        <AccordionItemState>
                          {({ expanded }) => {
                            const state = expanded ? "expanded" : "collapsed";
                            {
                              specialty.id === 1 &&
                                setAccordionOpen1(
                                  state === "expanded" ? "block" : "hidden"
                                );
                            }
                            {
                              specialty.id === 2 &&
                                setAccordionOpen2(
                                  state === "expanded" ? "block" : "hidden"
                                );
                            }
                            {
                              specialty.id === 3 &&
                                setAccordionOpen3(
                                  state === "expanded" ? "block" : "hidden"
                                );
                            }
                            {
                              specialty.id === 4 &&
                                setAccordionOpen4(
                                  state === "expanded" ? "block" : "hidden"
                                );
                            }
                            {
                              specialty.id === 5 &&
                                setAccordionOpen5(
                                  state === "expanded" ? "block" : "hidden"
                                );
                            }
                            {
                              specialty.id === 6 &&
                                setAccordionOpen6(
                                  state === "expanded" ? "block" : "hidden"
                                );
                            }
                          }}
                        </AccordionItemState>
                        <h3 className="text-xl tracking-wide mb-0">
                          {specialty.heading}
                        </h3>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="animate-fadeIn pt-5 md:pt-6 ml-10 md:ml-24">
                      <p className="text-sm md:text-base mb-0">
                        {specialty.text}
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                ))}
              </Accordion>
            </div>

            <div className="order-1 md:order-2">
              {specialties.map((specialty, i) => (
                <div key={specialty.id}>
                  {/* <div className="hidden md:block"> */}
                  <div
                    className={`animate-fadeIn 
                    ${specialty.id === 1 && accordionOpen1}
                    ${specialty.id === 2 && accordionOpen2}
                    ${specialty.id === 3 && accordionOpen3}
                    ${specialty.id === 4 && accordionOpen4}
                    ${specialty.id === 5 && accordionOpen5}
                    ${specialty.id === 6 && accordionOpen6}
                    `}
                  >
                    <GatsbyImage
                      image={specialty.imageDesktop}
                      alt={specialty.heading}
                    />
                  </div>
                  {/* </div> */}
                  {/* <div className="md:hidden">
                    <GatsbyImage
                      image={specialty.imageMobile}
                      alt={specialty.heading}
                      className={`animate-fadeIn 
                    ${specialty.id === 1 && accordionOpen1}
                    ${specialty.id === 2 && accordionOpen2}
                    ${specialty.id === 3 && accordionOpen3}
                    ${specialty.id === 4 && accordionOpen4}
                    ${specialty.id === 5 && accordionOpen5}
                    ${specialty.id === 6 && accordionOpen6}
                    `}
                    />
                  </div> */}
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <section className="mb-20 md:mb-32">
        <div className="container">
          <header className="text-center md:mb-14">
            <p className="font-bold uppercase text-gray-50 md:text-gray-50/50 tracking-widest mb-0">
              Our Clients
            </p>
          </header>
          <div className="grid grid-cols-3 md:grid-cols-4 lg:grid-cols-5 opacity-40 max-w-[1000px] mx-auto">
            {/* <GatsbyImage image={data.conrad.childImageSharp.gatsbyImageData} /> */}
            <GatsbyImage image={data.waldorf.childImageSharp.gatsbyImageData} />
            <GatsbyImage
              image={data.fourSeasons.childImageSharp.gatsbyImageData}
            />
            <GatsbyImage image={data.montaye.childImageSharp.gatsbyImageData} />
            <GatsbyImage
              image={data.luxuryCollection.childImageSharp.gatsbyImageData}
            />
            <GatsbyImage image={data.wyndham.childImageSharp.gatsbyImageData} />
            <GatsbyImage
              image={data.sheraton.childImageSharp.gatsbyImageData}
            />
            <GatsbyImage
              image={data.phoenician.childImageSharp.gatsbyImageData}
            />
            <GatsbyImage image={data.wHotels.childImageSharp.gatsbyImageData} />
            <GatsbyImage image={data.viceroy.childImageSharp.gatsbyImageData} />
            {/* <GatsbyImage image={data.hilton.childImageSharp.gatsbyImageData} /> */}
            <GatsbyImage image={data.hyatt.childImageSharp.gatsbyImageData} />
            <GatsbyImage
              image={data.littleNell.childImageSharp.gatsbyImageData}
            />
            <GatsbyImage image={data.parker.childImageSharp.gatsbyImageData} />
            <GatsbyImage
              image={data.lAuberge.childImageSharp.gatsbyImageData}
            />
            <GatsbyImage image={data.atria.childImageSharp.gatsbyImageData} />
            <GatsbyImage
              image={data.omniHotels.childImageSharp.gatsbyImageData}
            />

            <GatsbyImage
              image={data.nobleHouse.childImageSharp.gatsbyImageData}
            />
            {/* <GatsbyImage
              image={data.tgiFridays.childImageSharp.gatsbyImageData}
            /> */}
            {/* <GatsbyImage image={data.bjs.childImageSharp.gatsbyImageData} /> */}
            {/* <GatsbyImage image={data.chilis.childImageSharp.gatsbyImageData} /> */}
            {/* <GatsbyImage image={data.roys.childImageSharp.gatsbyImageData} /> */}
            {/* <GatsbyImage image={data.nick.childImageSharp.gatsbyImageData} /> */}
          </div>
        </div>
      </section>

      <CallToAction
        heading={[
          "Let’s Become ",
          <span className="text-primary-500">Partners</span>,
        ]}
        subtext="From short-term help to long-term staff members for your hotel, restaurant, or other establishments, we can get you the staffing you need. Contact us today!"
      />
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "open-graph/facebook/About.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "open-graph/twitter/About.jpg" }
    ) {
      publicURL
    }
    hero: file(relativePath: { eq: "about-us/1.0 Hero About - desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 817)
      }
    }
    sustainability: file(
      relativePath: { eq: "about-us/2.0 Sustainability desktop.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 560)
      }
    }
    cimsHonors: file(relativePath: { eq: "about-us/CIMS-Honors-logo.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 96)
      }
    }
    cimsGBHonors: file(
      relativePath: { eq: "about-us/CIMS-GB-Honors-logo.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 97)
      }
    }
    USGBC: file(relativePath: { eq: "about-us/USGBC-logo.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 64)
      }
    }
    luxuryHotelsDesktop: file(
      relativePath: { eq: "about-us/3.0 About luxury hotel desktop.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 560)
      }
    }
    resortsDesktop: file(
      relativePath: { eq: "about-us/3.1 About Resort desktop.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 560)
      }
    }
    spasDesktop: file(
      relativePath: { eq: "about-us/3.2 About Spa desktop.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 560)
      }
    }
    restaurantsDesktop: file(
      relativePath: { eq: "about-us/3.3 about restaurants desktop.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 560)
      }
    }
    eventSpacesDesktop: file(
      relativePath: { eq: "about-us/3.4 about Event Spaces desktop.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 560)
      }
    }
    commercialDesktop: file(
      relativePath: { eq: "about-us/3.5 About Commercial desktop.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 560)
      }
    }
    ritzCarlton: file(
      relativePath: { eq: "about-us/clients/ritz-carlton.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 193)
      }
    }
    waldorf: file(relativePath: { eq: "about-us/clients/waldorf.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 193)
      }
    }
    fourSeasons: file(
      relativePath: { eq: "about-us/clients/four-seasons.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 193)
      }
    }
    montaye: file(relativePath: { eq: "about-us/clients/montaye.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 193)
      }
    }
    luxuryCollection: file(
      relativePath: { eq: "about-us/clients/luxury-collection.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 193)
      }
    }
    stRegis: file(relativePath: { eq: "about-us/clients/st-regis.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 193)
      }
    }
    wyndham: file(relativePath: { eq: "about-us/clients/wyndham.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 193)
      }
    }
    westin: file(relativePath: { eq: "about-us/clients/westin.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 193)
      }
    }
    sheraton: file(relativePath: { eq: "about-us/clients/sheraton.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 193)
      }
    }
    phoenician: file(relativePath: { eq: "about-us/clients/phoenician.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 193)
      }
    }
    wHotels: file(relativePath: { eq: "about-us/clients/w-hotels.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 193)
      }
    }
    viceroy: file(relativePath: { eq: "about-us/clients/viceroy.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 193)
      }
    }
    marriott: file(relativePath: { eq: "about-us/clients/marriott.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 193)
      }
    }
    hilton: file(relativePath: { eq: "about-us/clients/hiltonhotels.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 193)
      }
    }
    hyatt: file(relativePath: { eq: "about-us/clients/hyatt.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 193)
      }
    }
    littleNell: file(relativePath: { eq: "about-us/clients/little-nell.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 193)
      }
    }
    parker: file(relativePath: { eq: "about-us/clients/parker.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 193)
      }
    }
    lAuberge: file(relativePath: { eq: "about-us/clients/l-auberge.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 193)
      }
    }
    atria: file(relativePath: { eq: "about-us/clients/atria.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 193)
      }
    }
    omniHotels: file(relativePath: { eq: "about-us/clients/omnihotels.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 193)
      }
    }
    conrad: file(relativePath: { eq: "about-us/clients/conrad.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 193)
      }
    }
    nobleHouse: file(relativePath: { eq: "about-us/clients/noble-house.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 193)
      }
    }
    tgiFridays: file(relativePath: { eq: "about-us/clients/tgi-fridays.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 193)
      }
    }
    clientLogo34: file(
      relativePath: { eq: "about-us/clients/3.3 logo bar.png.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 193)
      }
    }
    bjs: file(relativePath: { eq: "about-us/clients/bjs.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 193)
      }
    }
    chilis: file(relativePath: { eq: "about-us/clients/chilis.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 193)
      }
    }
    roys: file(relativePath: { eq: "about-us/clients/roys.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 193)
      }
    }
    nick: file(relativePath: { eq: "about-us/clients/nick.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 193)
      }
    }
  }
`;
export default Page;
