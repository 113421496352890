import React from "react";
import { useStaticQuery, graphql } from "gatsby";
// import { GatsbyImage } from "gatsby-plugin-image";

const WhyUs = ({ className, headingLevel }) => {
  const data = useStaticQuery(graphql`
    {
      service: file(
        relativePath: { eq: "repeating/value-props/1.0  Hotel.svg" }
      ) {
        publicURL
      }
      fastReliable: file(
        relativePath: { eq: "repeating/value-props/2.0 Customizable.svg" }
      ) {
        publicURL
      }
      dedicated: file(
        relativePath: { eq: "repeating/value-props/3.0 Exceptional staff.svg" }
      ) {
        publicURL
      }
      trusted: file(
        relativePath: { eq: "repeating/value-props/4.0 Superior.svg" }
      ) {
        publicURL
      }
      quality: file(
        relativePath: { eq: "repeating/value-props/5.0 trusted.svg" }
      ) {
        publicURL
      }
      unbeatable: file(
        relativePath: { eq: "repeating/value-props/6.0 Location.svg" }
      ) {
        publicURL
      }
    }
  `);

  const content = [
    {
      icon: data.service.publicURL,
      heading: "Not Just Hotel Cleaning",
      text: "We also specialize in contract and seasonal staffing, hotel management, and expanded cleaning services.",
    },
    {
      icon: data.fastReliable.publicURL,
      heading: "Customizable Programs",
      text: "No two properties are alike. We tailor each service and staffing program just for you.",
    },
    {
      icon: data.dedicated.publicURL,
      heading: "Exceptional Staff",
      text: "Our highly-trained team is committed to delivering the best experience for you and your guests.",
    },
    {
      icon: data.trusted.publicURL,
      heading: "Superior Service",
      text: "From immaculate cleaning to first-class hospitality, you’ll benefit from our benchmark of excellence.",
    },
    {
      icon: data.quality.publicURL,
      heading: "Trusted by Top Hotel Brands",
      text: "We specialize in luxury. We're trusted by top brands, including Loews Hotels, Miraval by Hyatt, The Ritz-Carlton and Sheraton.",
    },
    {
      icon: data.unbeatable.publicURL,
      heading: "Find Us Nationwide",
      text: "Our U.S. footprint is large and expanding. Contact HCS to explore opportunities in your city.",
    },
  ];

  return (
    <section className={`${className || "mb-28 md:mb-32"}`}>
      <div className="container">
        <header className="mb-14 md:mb-12 text-center md:text-left">
          <h2>Why HCS?</h2>
        </header>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-y-12 md:gap-y-20 md:gap-x-10">
          {content.map((content, i) => {
            return (
              <div key={i}>
                <div className="mb-6">
                  <img src={content.icon} alt={content.heading} />
                </div>
                <h3 className="text-lg md:text-xl mb-4 tracking-wide">
                  {content.heading}
                </h3>
                <p className="mb-0">{content.text}</p>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default WhyUs;
